import {Image, StyleSheet, TouchableOpacity, View} from 'react-native'
import {Text} from '../util/text/Text'
import {s, colors} from 'lib/styles'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {isWeb} from 'platform/detection'
import {web} from '#/alf'
import {ScrollView} from 'view/com/modals/util'
import {Trans, msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useModalControls} from '#/state/modals'
import {load, save} from '#/lib/storage'
import {useEffect, useState} from 'react'
import {CenteredView} from '../util/Views'
import {atoms as a, useBreakpoints, useTheme} from '#/alf'
import {Loader} from '#/components/Loader'
import {useQueryClient} from '@tanstack/react-query'
import {Button} from '#/components/Button'
import {RichText} from '@atproto/api'

export const snapPoints = ['50%']

const useDrafts = () => {
  const [drafts, setDrafts] = useState<Draft[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const loadDrafts = async () => {
      setIsLoading(true)
      try {
        const data = (await load('drafts')) || []
        setDrafts(data)
      } catch (error) {
        console.error('Failed to load drafts', error)
      } finally {
        setIsLoading(false)
      }
    }
    loadDrafts()
  }, [])

  const updateDrafts = (newDrafts: Draft[]) => {
    setDrafts(newDrafts)
    save('drafts', newDrafts)
  }

  return {drafts, isLoading, updateDrafts}
}

type Draft = {
  date: string
  richText: RichText
  images: any[]
}

export function Component({name}: {name: string}) {
  const pal = usePalette('default')
  const {closeModal} = useModalControls()
  const {isMobile} = useWebMediaQueries()
  const {_} = useLingui()
  const {gtMobile, gtTablet} = useBreakpoints()
  const t = useTheme()
  const {drafts, isLoading, updateDrafts} = useDrafts()
  const queryClient = useQueryClient()

  const onPressDraft = (draft: Draft, index: number) => {
    // set draft data using @tanstack/react-query
    queryClient.setQueryData(['draft'], draft)
    onPressDeleteDraft(index)
    closeModal()
  }

  const onPressDeleteDraft = (index: number) => {
    const newDrafts = drafts.filter((_, i) => i !== index)
    save('drafts', newDrafts)
    updateDrafts(newDrafts)
  }

  if (isLoading) {
    return (
      <CenteredView
        style={[
          a.flex_1,
          a.align_center,
          !gtMobile ? a.justify_between : a.gap_5xl,
          t.atoms.border_contrast_low,
          {paddingTop: 175, paddingBottom: 110},
        ]}
        sideBorders={gtMobile}
        topBorder={!gtTablet}>
        <View style={[a.w_full, a.align_center, {top: 100}]}>
          <Loader size="xl" />
        </View>
      </CenteredView>
    )
  }

  return (
    <View testID="draftsModal" style={[pal.view, styles.container]}>
      <View style={styles.titleSection}>
        <Text type="title-lg" style={[pal.text, styles.title]}>
          <Trans>Drafts</Trans>
        </Text>
      </View>

      <ScrollView>
        <View
          style={[
            styles.section,
            pal.border,
            {borderBottomWidth: 1, paddingHorizontal: isMobile ? 20 : 0},
          ]}>
          <View>
            {drafts.map((draft: Draft, index: number) => (
              <TouchableOpacity
                key={index}
                onPress={() => onPressDraft(draft, index)}
                accessibilityRole="button"
                style={[
                  a.p_md,
                  a.mb_xs,
                  {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: 8,
                  },
                  a.flex,
                  a.flex_row,
                  a.align_center,
                ]}>
                <Text
                  style={[
                    pal.text,
                    s.f16,
                    a.flex_1,
                    a.mr_sm,
                    web({
                      overflowWrap: 'anywhere',
                    }),
                  ]}>
                  {draft.richText?.unicodeText?.utf16 || 'Untitled'}
                </Text>
                <Image
                  source={{uri: draft.images[0]?.path}}
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 8,
                    marginRight: 10,
                  }}
                />
                <View style={{maxHeight: 40}}>
                  <Button
                    testID="deleteBtn"
                    label="Delete"
                    style={{
                      padding: 10,
                      height: 40,
                      backgroundColor: 'red',
                      borderRadius: 8,
                    }}
                    onPress={() => onPressDeleteDraft(index)}>
                    {({hovered, pressed}) => (
                      <Text style={{color: 'white'}}>Delete</Text>
                    )}
                  </Button>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </ScrollView>

      <View style={[styles.btnContainer, pal.borderDark]}>
        <TouchableOpacity
          testID="confirmBtn"
          onPress={() => {
            closeModal()
          }}
          style={styles.btn}
          accessibilityRole="button"
          accessibilityLabel={_(msg`Confirm`)}
          accessibilityHint="">
          <Text style={[s.white, s.bold, s.f18]}>
            <Trans context="action">Done</Trans>
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: isWeb ? 0 : 40,
  },
  titleSection: {
    paddingTop: isWeb ? 0 : 4,
    paddingBottom: isWeb ? 14 : 10,
  },
  title: {
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: 5,
  },
  description: {
    textAlign: 'center',
    paddingHorizontal: 32,
  },
  section: {
    borderTopWidth: 1,
    paddingVertical: 20,
  },
  adultExplainer: {
    paddingLeft: 5,
    paddingTop: 10,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    padding: 14,
    backgroundColor: colors.blue3,
  },
  btnContainer: {
    paddingTop: 20,
    paddingHorizontal: 20,
  },
})
